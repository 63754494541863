import { Table, Typography, ConfigProvider, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { Row, Card, Button, Popover } from "antd";

import { controller } from "../controller";

import arrowLeft from "../../assets/icons/arrow-circle-left.svg";
import arrowRight from "../../assets/icons/arrow-circle-right.svg";

import "./style.css";

const { Text } = Typography;

const TreatmentPlanTable = (props) => {
  const [data, setData] = useState([]);
  const [edulist, setEduList] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [hasDiscount, setHasDiscount] = useState(false);
  const [openPopoverKey, setOpenPopoverKey] = useState(null); // Track which Popover is open
  const [showModalID, setShowModalID] = useState(null); // Track which record's modal is open

  // Determine if educational content exists
  const contentExists =
    edulist &&
    edulist.educational_content &&
    edulist.educational_content.length > 0 &&
    edulist.educational_content.some(
      (item) => item.video_file && item.video_file.length > 0
    );

  // Get the total number of video files if educational_content exists
  const totalPages = contentExists
    ? edulist.educational_content.reduce(
        (total, item) => total + (item.video_file ? item.video_file.length : 0),
        0
      )
    : 0;

  const handleNext = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevious = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleShowModal = (record) => {
    setShowModalID(record.key);
    // Open the Popover by setting the openPopoverKey
    setOpenPopoverKey(record.key);
  };

  const handleEduList = async () => {
    var url = window.location.href;
    var urlParams = new URLSearchParams(url.slice(url.indexOf("?")));
    var patientId = urlParams.get("patient_id");
    try {
      var response = await controller.getTreatmentPlanDetail(
        showModalID,
        patientId
      );

      if (response.status < 250) {
        var data = await response.json;
        setEduList(data); // Set state with fetched data
        console.log(data);
      } else {
        console.log("Response status not OK:", response.status);
      }
    } catch (e) {
      console.error("Error fetching data:", e);
    }
  };

  useEffect(() => {
    if (showModalID) {
      handleEduList();
    }
  }, [showModalID, openPopoverKey]);

  useEffect(() => {
    // Reset currentPage when edulist changes
    setCurrentPage(0);
  }, [edulist]);

  useEffect(() => {
    // Check if the currently open Popover needs to redirect
    if (
      openPopoverKey !== null &&
      edulist &&
      edulist.educational_content &&
      edulist.educational_content.length > 0
    ) {
      var currentContent =
        edulist.educational_content[0].video_file[currentPage];
      if (
        currentContent &&
        currentContent.redirect === true &&
        currentContent.link
      ) {
        window.open(currentContent.link, "_blank", "noopener,noreferrer");
        setOpenPopoverKey(null); // Close the Popover after redirect
      }
    }
  }, [currentPage, edulist]);

  console.log('test', props.data)

  useEffect(() => {
    if (props.data) {
      // Flatten the data to include each treatment plan as a separate row
      var flattenedData = props.data && props.data.flatMap((item) =>
        item.treatment_plans.map((tp) => ({
          key: tp.id,
          patient: item.patient,
          note: item.note,
          status: item.status,
          created_date: item.created_date,
          updated_date: item.updated_date,
          name: tp.name,
          tooth: tp.tooth_from,
          estimated_cost: tp.estimated_cost,
          priority: tp.priority,
          insurance_coverage: tp.insurance_coverage,
          discount: tp.discount || null, // Set null if no discount
          patient_responsibility: tp.patient_responsibility || "-",
          video_link: tp.video_link,
          treatment_procedures: tp.treatment_procedures,
        }))
      );

      setData(flattenedData);

      // Check if there is any discount in the flattened data
      var discountExists = false;
      for (var i = 0; i < flattenedData.length; i++) {
        if (flattenedData[i].discount !== null) {
          discountExists = true;
          break;
        }
      }
      setHasDiscount(discountExists);
    }
  }, [props.data]);

  const rowClassName = (record, index) => {
    return index === 0 ? "hide-first-row" : "";
  };

  const columns = [
    {
      title: "Priority",
      dataIndex: "priority",
      key: "priority",
      sorter: function(a, b) {
        return a.priority - b.priority;
      },
      defaultSortOrder: "ascend",
      width: hasDiscount ? "9%" : "10%",
      render: function(priority) {
        return (
          <Row align="bottom">
            {priority !== null ? priority : "-"}
          </Row>
        );
      },
    },
    {
      title: "Treatment",
      dataIndex: "name",
      key: "name",
      width: hasDiscount ? "13%" : "15%",
      render: function(name) {
        return (
          <Row align="bottom" style={{ fontWeight: "bold" }}>
            {name ? name : "-"}
          </Row>
        );
      },
    },
    {
      title: "Tooth / Quadrant",
      dataIndex: "tooth",
      key: "tooth",
      width: hasDiscount ? "9%" : "10%",
      render: function(tooth) {
        return (
          <Row align="bottom">
            {tooth ? tooth : "-"}
          </Row>
        );
      },
    },
    {
      title: "Estimated Cost",
      dataIndex: "estimated_cost",
      key: "estimated_cost",
      width: hasDiscount ? "15.5%" : "17.5%",
      ellipsis: true,
      render: function(estimated_cost) {
        return (
          <Row align="bottom">
            ${estimated_cost ? parseFloat(estimated_cost).toFixed(2) : "-"}
          </Row>
        );
      },
    },
    {
      title: "Insurance Coverage",
      dataIndex: "insurance_coverage",
      key: "insurance_coverage",
      width: hasDiscount ? "15.5%" : "17.5%",
      ellipsis: true,
      render: function(insurance_coverage) {
        return (
          <Row align="bottom">
            ${insurance_coverage ? insurance_coverage : "-"}
          </Row>
        );
      },
    },
    {
      title: "Your Responsibility",
      dataIndex: "patient_responsibility",
      key: "patient_responsibility",
      width: hasDiscount ? "15.5%" : "17.5%",
      ellipsis: true,
      render: function(patient_responsibility) {
        return (
          <Row align="bottom">
            ${patient_responsibility ? patient_responsibility : "-"}
          </Row>
        );
      },
    },
    ...(hasDiscount
      ? [
          {
            title: "Discount",
            dataIndex: "discount",
            key: "discount",
            width: hasDiscount ? "14.5%" : "17.5%",
            ellipsis: true,
            render: function(discount) {
              return (
                <Row align="bottom">
                  %{discount || "0"}
                </Row>
              );
            },
          },
        ]
      : []),
    {
      title: "Patient Education",
      dataIndex: "video_link",
      key: "video_link",
      width: "15%", // Standard width
      ellipsis: true,
      render: function(video_link, record) {
        return (
          <Row align="bottom">
            <Popover
              open={openPopoverKey === record.key}
              onOpenChange={function(visible) {
                if (visible) {
                  handleShowModal(record); // Set the current record's key
                } else {
                  setOpenPopoverKey(null); // Close the Popover
                }
              }}
              showArrow={false}
              placement="bottomRight"
              content={function() {
                return (
                  <div className="card-in-modal">
                    <div className="insideCardModal">
                      {edulist &&
                      edulist.educational_content &&
                      edulist.educational_content.length > 0 ? (
                        <div>
                          <div style={{ display: "flex", flexDirection: "row" }}>
                            <p style={{ fontSize: 16, marginTop: 25 }}>
                              Treatment Description
                            </p>
                          </div>
                          <div style={{ marginBottom: "20px" }}>
                            <p style={{ fontSize: 12, color: "#848696" }}>
                              {edulist.educational_content[0] &&
                              edulist.educational_content[0].description
                                ? edulist.educational_content[0].description
                                : "No description available"}
                            </p>
                          </div>
                          <div
                            style={{
                              marginBottom: 15,
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <p style={{ fontSize: 16 }}>Educational Content</p>
                          </div>
                          <Card>
                            {edulist.educational_content[0] &&
                            edulist.educational_content[0].video_file &&
                            edulist.educational_content[0].video_file[currentPage] &&
                            edulist.educational_content[0].video_file[currentPage].link &&
                            (edulist.educational_content[0].video_file[currentPage].link.indexOf("youtube.com") !== -1 ||
                              edulist.educational_content[0].video_file[currentPage].link.indexOf("spearedu.co") !== -1 ||
                              edulist.educational_content[0].video_file[currentPage].link.indexOf("patient-api.speareducation.com") !== -1) ? (
                              <iframe
                                width="100%"
                                height="246px"
                                src={edulist.educational_content[0].video_file[currentPage].link.replace("watch?v=", "embed/")}
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                                title="Educational Video"
                              ></iframe>
                            ) : (
                                <video width="100%" height="246px" controls>
                                <source
                                  src={
                                    edulist &&
                                    edulist.educational_content &&
                                    edulist.educational_content.length > 0 &&
                                    edulist.educational_content[0].video_file &&
                                    edulist.educational_content[0].video_file.length > currentPage &&
                                    edulist.educational_content[0].video_file[currentPage] &&
                                    edulist.educational_content[0].video_file[currentPage].video
                                      ? edulist.educational_content[0].video_file[currentPage].video
                                      : (
                                          edulist &&
                                          edulist.educational_content &&
                                          edulist.educational_content.length > 0 &&
                                          edulist.educational_content[0].video_file &&
                                          edulist.educational_content[0].video_file.length > currentPage &&
                                          edulist.educational_content[0].video_file[currentPage] &&
                                          edulist.educational_content[0].video_file[currentPage].link
                                            ? edulist.educational_content[0].video_file[currentPage].link
                                            : ""
                                        )
                                  }
                                  type="video/mp4"
                                />
                                Your browser does not support the video tag.
                              </video>
                              
                            )}
                          </Card>
                          <Row
                            justify="center"
                            style={{
                              marginTop: 20,
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Button
                              type="text"
                              icon={<img src={arrowLeft} alt="Previous" />}
                              style={{ marginTop: 25 }}
                              disabled={currentPage === 0}
                              onClick={handlePrevious}
                            />
                            <span
                              style={{
                                margin: "0 10px",
                                lineHeight: "1.5",
                                marginTop: 25,
                              }}
                            >
                              {currentPage + 1}
                            </span>
                            <Button
                              type="text"
                              icon={<img src={arrowRight} alt="Next" />}
                              style={{ marginTop: 25 }}
                              disabled={currentPage === totalPages - 1}
                              onClick={handleNext}
                            />
                          </Row>
                        </div>
                      ) : (
                        <p>No educational content available</p>
                      )}
                    </div>
                  </div>
                );
              }}
              trigger="click"
            >
              <Text
                underline
                style={{
                  color: "#6B43B5",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                Learn More
              </Text>
            </Popover>
          </Row>
        );
      },
    },
  ].filter(function(column) { return true; }); // Retain all columns

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#983cfc",
          controlItemBgHover: "#c293ff",
          colorLink: "#983cfc",
        },
        components: {
          Table: {
            borderRadius: "8px",
            borderColor: "#eee",
          },
        },
      }}
    >
      <Table
        dataSource={data}
        columns={columns}
        pagination={false}
        rowClassName={rowClassName}
        scroll={{ x: "max-content" }}
        expandable={{
          expandedRowRender: function(record) {
            // Find the treatment plan
            var treatmentPlan = null;
            if (
              props.data &&
              props.data.length > 0 &&
              props.data[0].treatment_plans
            ) {
              for (var i = 0; i < props.data[0].treatment_plans.length; i++) {
                if (props.data[0].treatment_plans[i].id === record.key) {
                  treatmentPlan = props.data[0].treatment_plans[i];
                  break;
                }
              }
            }

            if (!treatmentPlan) {
              return <p>No treatment plans available</p>;
            }

            if (
              !treatmentPlan.treatment_procedures ||
              treatmentPlan.treatment_procedures.length === 0
            ) {
              return <p>No treatment found</p>;
            }

            var expandedData = treatmentPlan.treatment_procedures.map(
              function(procedure, index) {
                var discountValue = "%0";
                if (
                  procedure.discount &&
                  procedure.discount !== "0.00" &&
                  procedure.discount !== "0"
                ) {
                  discountValue = "%" + procedure.discount;
                }

                return {
                  key: index,
                  description: (
                    <Tooltip
                      title={
                        (procedure.procedure_code &&
                          procedure.procedure_code.procedure_code) ||
                        "-"
                      }
                      placement="top"
                    >
                      <div
                        style={{
                          display: "-webkit-box",
                          WebkitBoxOrient: "vertical",
                          WebkitLineClamp: 2,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: "100%",
                          whiteSpace: "normal",
                          alignItems: "center",
                          marginTop: 10,
                        }}
                      >
                        {procedure.procedure_code &&
                        procedure.procedure_code.procedure_code &&
                        procedure.procedure_code.procedure_code_description
                          ? procedure.procedure_code.procedure_code +
                            " - " +
                            procedure.procedure_code.procedure_code_description
                          : "-"}
                      </div>
                    </Tooltip>
                  ),
                  tooth: procedure.tooth || "-",
                  estimated_cost: procedure.estimated_cost
                    ? "$" + procedure.estimated_cost
                    : "-",
                  insurance_estimate: procedure.insurance_estimate
                    ? "$" + procedure.insurance_estimate
                    : "-",
                  patient_responsibility: procedure.patient_responsibility
                    ? "$" + procedure.patient_responsibility
                    : "-",
                  discount: discountValue,
                };
              }
            );

            var expandedColumns = [
              {
                title: "Priority",
                dataIndex: "priority",
                key: "priority",
                width: hasDiscount ? "9%" : "10%",
              },
              {
                title: "Treatment",
                dataIndex: "description",
                key: "description",
                width: hasDiscount ? "12%" : "15%",
              },
              {
                title: "Tooth / Quadrant",
                dataIndex: "tooth",
                key: "tooth",
                width: hasDiscount ? "9%" : "10%",
              },
              {
                title: "Estimated Cost",
                dataIndex: "estimated_cost",
                key: "estimated_cost",
                width: hasDiscount ? "14.5%" : "17.5%",
              },
              {
                title: "Insurance Coverage",
                dataIndex: "insurance_estimate",
                key: "insurance_estimate",
                width: hasDiscount ? "15.5%" : "17.5%",
              },
              {
                title: "Your Responsibility",
                dataIndex: "patient_responsibility",
                key: "patient_responsibility",
                width: hasDiscount ? "15.5%" : "17.5%",
              },
              ...(hasDiscount
                ? [
                    {
                      title: "Discount",
                      dataIndex: "discount",
                      key: "discount",
                      width: hasDiscount ? "14.5%" : "17.5%",
                    },
                  ]
                : []),
              {
                title: "Patient Education",
                dataIndex: "video_link",
                key: "video_link",
                width: "40%",
              },
            ];

            return (
              <Table
                columns={expandedColumns}
                dataSource={expandedData}
                className="nested-table-no-header"
                pagination={false}
                showHeader={false}
              />
            );
          },
        }}
      />
    </ConfigProvider>
  );
};

export default TreatmentPlanTable;
