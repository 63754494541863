import React from "react";
import { useLocation } from "react-router-dom";

import TreatmentPlan from "../../../New/Treatment Plan/TreatmentPlan";

export default function Preview() {
    const location = useLocation();
    const { filteredData2, imageFile } = location.state || {}; // Fallback to {} if state is undefined
    console.log(filteredData2)
    console.log(imageFile)
  return (
    <>
        <>
        <TreatmentPlan preview={filteredData2} image = {imageFile} />
        </>
    </>
  );
}
